"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toCommonJS = (mod) => __copyProps(__defProp({}, "__esModule", { value: true }), mod);

// src/index.ts
var src_exports = {};
__export(src_exports, {
  createNuxtPersistedState: () => createNuxtPersistedState,
  createPersistedState: () => createPersistedState,
  default: () => persistedState
});
module.exports = __toCommonJS(src_exports);

// src/normalize.ts
function isObject(v) {
  return typeof v === "object" && v !== null;
}
function normalizeOptions(options, globalOptions) {
  options = isObject(options) ? options : /* @__PURE__ */ Object.create(null);
  return new Proxy(options, {
    get(target, key, receiver) {
      return Reflect.get(target, key, receiver) || Reflect.get(globalOptions, key, receiver);
    }
  });
}

// src/pick.ts
function get(state, path) {
  return path.reduce((obj, p) => {
    return obj == null ? void 0 : obj[p];
  }, state);
}
function set(state, path, val) {
  return path.slice(0, -1).reduce((obj, p) => {
    if (!/^(__proto__)$/.test(p))
      return obj[p] = obj[p] || {};
    else
      return {};
  }, state)[path[path.length - 1]] = val, state;
}
function pick(baseState, paths) {
  return paths.reduce((substate, path) => {
    const pathArray = path.split(".");
    return set(substate, pathArray, get(baseState, pathArray));
  }, {});
}

// src/plugin.ts
function createPersistedState(factoryOptions = {}) {
  return function(context) {
    const {
      options: { persist },
      store
    } = context;
    if (!persist)
      return;
    const {
      storage = localStorage,
      beforeRestore = null,
      afterRestore = null,
      serializer = {
        serialize: JSON.stringify,
        deserialize: JSON.parse
      },
      key = store.$id,
      paths = null
    } = normalizeOptions(persist, factoryOptions);
    beforeRestore == null ? void 0 : beforeRestore(context);
    try {
      const fromStorage = storage.getItem(key);
      if (fromStorage instanceof Promise) {
        Promise.resolve(fromStorage).then((value) => {
          if (value)
            store.$patch(serializer.deserialize(value));
          afterRestore == null ? void 0 : afterRestore(context);
        });
      } else if (fromStorage) {
        store.$patch(serializer.deserialize(fromStorage));
        afterRestore == null ? void 0 : afterRestore(context);
      }
    } catch (_error) {
      afterRestore == null ? void 0 : afterRestore(context);
    }
    let setItemPromise = null;
    store.$subscribe((_mutation, state) => {
      try {
        const toStore = Array.isArray(paths) ? pick(state, paths) : state;
        if (setItemPromise) {
          setItemPromise = setItemPromise.then(() => storage.setItem(key, serializer.serialize(toStore)));
        } else {
          const item = storage.setItem(key, serializer.serialize(toStore));
          if (item instanceof Promise) {
            setItemPromise = item;
          }
        }
      } catch (_error) {
      }
    }, { detached: true });
  };
}
function createNuxtPersistedState(useCookie, factoryOptions) {
  return createPersistedState({
    storage: {
      getItem: (key) => {
        return useCookie(key, {
          encode: encodeURIComponent,
          decode: decodeURIComponent,
          ...factoryOptions == null ? void 0 : factoryOptions.cookieOptions
        }).value;
      },
      setItem: (key, value) => {
        useCookie(key, {
          encode: encodeURIComponent,
          decode: decodeURIComponent,
          ...factoryOptions == null ? void 0 : factoryOptions.cookieOptions
        }).value = value;
      }
    },
    ...factoryOptions
  });
}
var persistedState = createPersistedState();
// Annotate the CommonJS export names for ESM import in node:
0 && (module.exports = {
  createNuxtPersistedState,
  createPersistedState
});
